import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class FreeDownloadTips extends React.Component {
	render() {
		const siteTitle = 'Firm Profile'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Free Download Tips</h3>
								<p>
									Please subscribe your details and we'll direct you to the
									download page{' '}
									<strong>"Ten Essential Cash Flow Property Tips"</strong> thank
									you..
								</p>
								<div id="freetips">
									<form
										className="contact-form"
										id="form-validation-test2"
										name="WSC | Free Download Tips"
										action="/about-us/david-shaws-10-property-cash-flows-tips"
										netlify="netlify"
										netlify-honeypot="bot-field"
									>
										<input
											type="hidden"
											name="subject"
											defaultValue="Contact Details"
										/>
										<div className="row">
											<div className="col-12 mb-3">
												<div className="form-group">
													<input
														className="form-control"
														type="text"
														name="Name"
														id="name"
													/>
													<label htmlFor="name">Full Name</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 mb-3">
												<div className="form-group">
													<input
														className="form-control"
														type="email"
														name="Email Address"
														id="emailaddress"
													/>
													<label htmlFor="emailaddress">EMAIL ADDRESS</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="eq-ui-form-group eq-ui-input-field">
													<p>State / Territory</p>
													<select className="eq-ui-select">
														<option value disabled selected>
															Choose your State / Territory
														</option>
														<option value={1} name="State / Country">
															NSW
														</option>
														<option value={2} name="State / Country">
															QLD
														</option>
														<option value={3} name="State / Country">
															VIC
														</option>
														<option value={4} name="State / Country">
															SA
														</option>
														<option value={5} name="State / Country">
															WA
														</option>
														<option value={6} name="State / Country">
															TAS
														</option>
													</select>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="webriq-recaptcha" />
											</div>
										</div>
										<div className="row mt-3">
											<div className="input-field col-md-12 contbtn">
												<button
													className="btn btn-primary eq-ui-waves-light"
													type="submit"
													name="submit"
													value="submit"
													id="form-validation-test2-submit"
												>
													SUBMIT
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default FreeDownloadTips

export const FreeDownloadTipsPageQuery = graphql`
	query FreeDownloadTipsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
